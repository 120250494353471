.footer {
    background-color: #0C8883;
    padding: 90px 0;
    color: #fff;
}

.footer_content {
    display: flex;
    justify-content: space-between;
}

.footer_logo {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 750;
}

.footer_logo img {
    width: 100px;

}

.footer_desc {
    color: #fff;
}

.footer_item:first-child {
    font-size: 18px;
}

.footer_nav li {
    margin-bottom: 10px;
}

.footer-title {
    font-size: 26px;
    margin-bottom: 10px;
}

.nav_link {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
}

.footer_location {
    font-size: 18px;
    color: #fff;
}

.yandex-map {
    margin-top: 30px;
    border-radius: 10px;
    width: 500px;
    height: 300px;
    overflow: hidden;
}

@media (max-width: 1250px) {
    .footer_item +.footer_item {
        margin-left: 20px;
    }
}

@media (max-width: 1030px) {
    .footer {
        padding: 50px 0;
    }
    .footer_content {
        flex-direction: column;
        align-items: center;
    }
    .footer_item {
        margin-bottom: 15px;
        text-align: center;
    }
    .footer_item +.footer_item {
        margin-left: 0;
    }
    .footer_logo {
        justify-content: center;
    }
    .footer_desc {
        font-size: 16px;
    }
}

@media (max-width: 570px) {
    .yandex-map {
        width: 350px;
        height: 200px;
    }
    .footer_desc {
        font-size: 12px;
    }
}

@media (max-width: 360px) {
    .yandex-map {
        width: 250px;
        height: 180px;
    }
}
