.container {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 1250px) {
  .container {
    max-width: 1000px;
  }
}

/* Стили для анимации */
.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 500ms, transform 500ms;
}

/* Стили для загрузки */
.content.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #333;
  background: #f4f4f4;
}

.loading-spinner {
  font-size: 24px;
  color: #007bff;
}





