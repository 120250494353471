/* Базовые стили шапки */
.header {
    position: relative;
    width: 100%;
    background-color: #ffffff;
  }
  
  .header_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    max-width: 1200px;
    width: 100%;
    margin: 0 30px;
    margin-top: 70px;
    z-index: 10;
  }
  
  .header_logo {
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: 750;
    color: #486A6F;
  }
  
  .logo {
    width: 50px;
    margin-right: 10px;
    border-radius: 50%;
  }
  
  /* Стили навигации */
  .nav {
    display: flex;
    gap: 30px;
    transition: transform 0.3s ease;
  }
  
  .nav__link {
    color: #4c7665;
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
  }
  
  .nav__link:hover {
    color: #0C8883;
  }
  
  /* Кнопка */
  .header_btn {
    color: #ffffff;
    background-color: #0C8883;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.2s linear;
  }
  
  .header_btn:hover {
    background-color: #32747e;
  }
  
  /* Стили гамбургера */
  .hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;
    cursor: pointer;
  }
  
  .hamburger span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #486A6F;
    transition: all 0.3s ease;
  }
  
  .hamburger.is-active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .hamburger.is-active span:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.is-active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;  
  }
  
  /* Навигация для мобильных */
  .nav {
    position: absolute;
    top: 0px;
    left: -260px;
    height: 100vh;
    width: 500px;
    background-color: #2B706D;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 20px;
    gap: 20px;
    transform: translateX(-100%);
    z-index: 9;
    margin-top: -70px;
    display: flex;
    transition: all 1s ease;
  }
  
  .nav_active {
    transform: translateX(230px);
  }

  @media (max-width: 1380px) {
    .header_inner {
        width: 90%;
    }
  }

@media (max-width: 1050px) { 
  .menu a, .header_btn {
    font-size: 15px;
  }
  .header_logo span {
    font-size: 22px;
  }
  .logo  {
    width: 40px;
  }
  .header_inner {
    max-width: 870px;
  }
}
  
@media (max-width: 950px) {
    .header_inner {
        width: 90%;
    }
    .header_inner-active {
        position: fixed;
    }
    .nav {
        display: flex;
    }

    .menu {
        display: none;
    }

    .nav__link {
        color: #fff;
        transition: all 0.2s linear;
    }
    .nav__link:hover {
        color: #dcdcdc;
    }

    .hamburger {
        display: flex;
    }

    .header_btn {
        display: none; /* Скрываем кнопку на мобильных */
    }

    .header_inner {
        justify-content: space-between;
    }
}

@media (max-width: 640px) {
    .nav_active {
        width: 70%;
    }
}
  





