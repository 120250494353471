.loading-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .loading-page p {
    margin-top: 16px;
    font-size: 16px;
    color: #555;
  }
  